<template lang="pug">
  .matchings-table
    .matchings-table-content
      table.container-fluid
        thead
          tr
            th.sync.sortable(@click="handleSorting('sync')")
              span {{ $t("car_class_matching.sync") }}
              FaIcon.icon(:icon="sortingIcon('sync')")
            th.item-name.sortable(@click="handleSorting('name')")
              span {{ $t("car_class_matching.car_class_name") }}
              FaIcon.icon(:icon="sortingIcon('name')")
            th.dp.sortable(v-if="currentOrganization.dp" @click="handleSorting('dp')")
              span {{ $t("car_class_matching.dp") }}
              FaIcon.icon(:icon="sortingIcon('dp')")
            th.inbound.sortable(v-if="currentOrganization.inbound" @click="handleSorting('inbound')")
              span {{ $t("car_class_matching.inbound") }}
              FaIcon.icon(:icon="sortingIcon('inbound')")
            th.sources-item(
              v-for="{ id, rawName, name } in translatedOtaList"
              :class="rawName"
              :key="id"
            )
              | {{ name }}
            th.actions
        tbody
          template(v-for="carClassesStore in [carClassesAdded, carClasses]")
            CarClassItem(
              v-for="(carClass, index) in carClassesStore"
              :key="carClass.id || carClass._addedAt"
              :item="carClass"
              :index="index"
              :ota-list="translatedOtaList"
              :sources-items-by-ota-id="sourcesCarClassesByOtaId"
              :selected-sources-items-by-ota-id="selectedSourcesCarClasses"
              :update-item="updateItem(carClass)"
              :delete-item="() => deleteItem(carClass)"
              :add-selected-sources-item="addSelectedSourcesCarClass"
              :remove-selected-sources-item="removeSelectedSourcesCarClass"
              :immutable-ota-ids="carClass.immutable_ota_ids"
              :sources-items-loading="sourcesCarClassesLoading"
              :invalid-item-ids="invalidItemIds"
            )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"
  import withBrowserDetect from "@/mixins/withBrowserDetect"
  import withConfirmation from "@/mixins/withConfirmation"

  // misc
  import { mapGetters } from "vuex"

  export default {
    mixins: [withSorting, withBrowserDetect, withConfirmation],

    props: {
      carClasses: Array,
      carClassesAdded: Array,
      sourcesCarClassesByOtaId: Object,
      updateCarClass: Function,
      updateAddedCarClass: Function,
      deleteCarClass: Function,
      deleteAddedCarClass: Function,
      selectedSourcesCarClasses: {
        type: Object,
        required: true
      },
      addSelectedSourcesCarClass: Function,
      removeSelectedSourcesCarClass: Function,
      invalidItemIds: {
        type: Array,
        default: () => new Array()
      },
      sourcesCarClassesLoading: {
        type: Boolean,
        default: true
      },
      sortingData: Object
    },

    components: {
      CarClassItem: () => import("./CarClassItem")
    },

    computed: mapGetters(["translatedOtaList", "currentOrganization"]),

    methods: {
      updateItem(item) {
        return item._addedAt ? this.updateAddedCarClass : this.updateCarClass
      },

      deleteItem(carClass) {
        this.$confirm({
          title: this.$t("car_class_matching.delete_confirmation", { name: carClass.name }),
          resolve: {
            handler: () => {
              const deleteMethod = carClass._addedAt ? this.deleteAddedCarClass : this.deleteCarClass
              const index = carClass._addedAt
                ? this.carClassesAdded.findIndex(carClassItem => carClassItem.id === carClass.id)
                : this.carClasses.findIndex(carClassItem => carClassItem.id === carClass.id)
              deleteMethod(index)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-table
    +matchings-table-header-sticky

    thead
      th
        &.inbound
          min-width: 50px
</style>
